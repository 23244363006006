import React, { useEffect, useState } from "react";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import { appAxios } from "../../helpers/app-axios";
import AppToast from "../toast";
import Loader from "../loader";
// import AppToast from "../toast";

const Inquiries = () => {
  const [reply, SetReply] = useState(false);
  const [isToastShow, setIsToastShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [query, SetQuery] = useState("");
  const [mails, setMails] = useState(null);
  useEffect(() => {
    getMails();
  }, []);
  const getMails = async () => {
    setIsApiLoading(true)
    const { data } = await appAxios.get(`/contact`);
    setIsApiLoading(false)
    if (data.isSuccess) {
      setMails(data?.data);
    }
  };
  const showReply = (id) => {
    SetReply(id);
  };
  const sendReply = async (message, mail) => {
    let obj = {
      isReply: true,
      subject: mail?.subject || "test subject",
      email: mail?.email,
      message: message,
      fullName: "admin",
    };
    setIsLoading(true);

    const { data } = await appAxios.post(`/admin/message/reply`, obj);
    setIsToastShow(true);
    setIsLoading(false);
    setTimeout(() => {
      setIsToastShow(false);
    }, 3000);
    if (data.isSuccess) {
      setMessage("Your message has been send successfully!.");
      setIsToastShow(true);
      // restToast();
      AppToast("Your message has been send successfully!.", "success");
      getMails();
    } else {
      setMessage(data?.mesasge || data?.data?.mesasge);
      setIsToastShow(true);
      // AppToast(data?.mesasge || data?.data?.mesasge, "error")

      // restToast();
    }
  };
  const restToast = () => {
    setMessage("");
    setIsToastShow(false);
  };
  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        {isToastShow && (
          <AppToast message={message} type="success" restToast={restToast} />
        )}

        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box p-5">
                  <div className="row">
                    <div className="col-12">
                      {isApiLoading ? <div className="text-center">  <Loader type="text-dark"/></div> : mails &&
                        mails.length > 0 &&
                        mails.map((mail, index) => {
                          return (
                            <div className="chemical-wrapper" key={index}>
                              <div className="row mb-3">
                                <div className="col-12 col-md-4">
                                  <h2 className="name">Name</h2>
                                  <p>{mail?.fullName}</p>
                                </div>
                                <div className="col-12 col-md-4">
                                  <h2 className="name">Email</h2>
                                  <p>{mail?.email}</p>
                                </div>
                                <div className="col-12 col-md-4">
                                  <h2 className="name">Subject</h2>
                                  <p>{mail?.subject}</p>
                                </div>
                                <div className="col-12">
                                  <h2 className="name">Message</h2>
                                  <p>{mail?.message}</p>
                                </div>
                              </div>

                              <div className="d-flex justify-content-center mb-4">
                                <button
                                  className="btn btn-custom btn-custom-large waves-effect waves-light"
                                  onClick={() => showReply(mail?._id)}
                                >
                                  Reply
                                </button>
                              </div>
                              {reply === mail?._id && (
                                <div className="mt-1">
                                  <div className="input-group mb-3">
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={query}
                                      onChange={(e) => SetQuery(e.target.value)}
                                      placeholder="Reply to customer inquiry"
                                    />

                                    <button
                                      className="input-group-text btn-custom"
                                      id="basic-addon2"
                                      onClick={() => sendReply(query, mail)}
                                    >
                                      {isLoading && (
                                        <div
                                          class="spinner-border text-light spinner-border-sm mr-2"
                                          role="status"
                                        >
                                          <span class="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      )}
                                      Submit
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default Inquiries;
