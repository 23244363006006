import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
  initializing: true,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserSlice(state, { payload }) {
      const { user, initializing } = payload;
      if (typeof user !== "undefined") state.user = user;
      if (typeof initializing !== "undefined")
        state.initializing = initializing;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    unsetUser(state) {
      state.user = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserSlice, setUser, unsetUser } = userSlice.actions;

export default userSlice.reducer;
