import React, { useState } from "react";
import Topbar from "../admin/Topbar";
import Sidebar from "../admin/Sidebar";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { appAxios } from "../../helpers/app-axios";

const validationSchema = Yup.object({
  name: Yup.string().trim().required("Chemical name is required"),
  category: Yup.string().trim().required("Category is required"),
  activeIngredient: Yup.string()
    .trim()
    .required("Active Ingredient is required"),
  supplier: Yup.string().trim().required("Supplier is required"),
  manufuctureDate: Yup.date().required("Manufucture Date is required"),
  batchNumber: Yup.string().trim().required("Batch Number is required"),
  uploadFile: Yup.mixed().required("Please select an attachment"),
});

const AddChemical = () => {
  const [isLoader, setIsLoader] = useState(false);

  const {
    handleChange,
    values,
    setFieldValue,
    errors,
    handleSubmit,
    touched,
    resetForm,
  } = useFormik({
    initialValues: {
      name: "",
      category: "",
      activeIngredient: "",
      supplier: "",
      manufuctureDate: "",
      batchNumber: "",
      uploadFile: null,
    },
    validationSchema,
    async onSubmit(values) {
      try {
        setIsLoader(true);
        const formData = new FormData();
        formData.append("name", values.name);
        formData.append("category", values.category);
        formData.append("activeIngredient", values.activeIngredient);
        formData.append("supplier", values.supplier);
        formData.append("manufuctureDate", values.manufuctureDate);
        formData.append("batchNumber", values.batchNumber);
        formData.append("uploadFile", values.uploadFile);

        await appAxios.post("/admin/chemical/add", formData, {
          headers: { "content-type": "multipart/form-data" },
        });
        setIsLoader(false);

        resetForm();
      } catch (error) {
        setIsLoader(false);

        console.log(error);
      }
    },
  });
  const options = [
    { value: "category1", label: "category1" },
    { value: "category2", label: "category2" },
    { value: "category3", label: "category3" },
  ];

  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box">
                  <div className="card-box p-5">
                    <h2 className="text-uppercase text-center pb-4">
                      Add New Inventory Item
                    </h2>

                    <form className="" onSubmit={handleSubmit}>
                      <div className="form-group mb-4">
                        <label>Chemical name</label>
                        <input
                          className="form-control"
                          placeholder="Chemical name"
                          name="name"
                          type="text"
                          onChange={handleChange}
                          value={values.name}
                        />
                        {touched.name && errors.name && (
                          <p className="mt-2 text-danger">{errors.name}</p>
                        )}
                      </div>

                      <div className="form-group  mb-4">
                        <label>Category/Type</label>
                        <input
                          className="form-control"
                          placeholder="category name"
                          name="category"
                          type="text"
                          onChange={handleChange}
                          value={values.category}
                        />
                        {/* <Select
                          options={options}
                          placeholder="Select a category"
                          isClearable={true}
                          isSearchable={true}
                          // className="form-control"
                          name="category"
                          onChange={(newValue) =>
                            setFieldValue("category", newValue.value)
                          }
                          value={options.find(
                            (o) => o.value === values.category
                          )}
                        /> */}

                        {touched.category && errors.category && (
                          <p className="mt-2 text-danger">{errors.category}</p>
                        )}
                      </div>

                      <div className="form-group  mb-4">
                        <label>Active Ingredient</label>
                        <input
                          className="form-control"
                          placeholder="Active Ingredient"
                          name="activeIngredient"
                          type="text"
                          onChange={handleChange}
                          value={values.activeIngredient}
                        />
                        {touched.activeIngredient &&
                          errors.activeIngredient && (
                            <p className="mt-2 text-danger">
                              {errors.activeIngredient}
                            </p>
                          )}
                      </div>
                      <div className="form-group  mb-4">
                        <label>Supplier</label>
                        <input
                          className="form-control"
                          placeholder="Supplier"
                          name="supplier"
                          type="text"
                          onChange={handleChange}
                          value={values.supplier}
                        />
                        {touched.supplier && errors.supplier && (
                          <p className="mt-2 text-danger">{errors.supplier}</p>
                        )}
                      </div>
                      <div className="form-group  mb-4">
                        <label>Date of Manufacture</label>
                        <input
                          className="form-control"
                          placeholder="Date of Manufacture"
                          name="manufuctureDate"
                          type="date"
                          onChange={handleChange}
                          value={values.manufuctureDate}
                        />
                        {touched.manufuctureDate && errors.manufuctureDate && (
                          <p className="mt-2 text-danger">
                            {errors.manufuctureDate}
                          </p>
                        )}
                      </div>
                      <div className="form-group  mb-4">
                        <label>Batch Number</label>
                        <input
                          className="form-control"
                          placeholder="Batch Number"
                          name="batchNumber"
                          type="text"
                          onChange={handleChange}
                          value={values.batchNumber}
                        />
                        {touched.batchNumber && errors.batchNumber && (
                          <p className="mt-2 text-danger">
                            {errors.batchNumber}
                          </p>
                        )}
                      </div>
                      <div className="form-group  mb-4">
                        <label>PDF Attachment</label>
                        <input
                          className="form-control"
                          type="file"
                          onChange={(e) =>
                            setFieldValue(
                              "uploadFile",
                              e.target.files.length > 0
                                ? e.target.files.item(0)
                                : null
                            )
                          }
                          accept="application/pdf,images/*"
                          placeholder="PDF Attachment"
                        />
                        {touched.uploadFile && errors.uploadFile && (
                          <p className="mt-2 text-danger">
                            {errors.uploadFile}
                          </p>
                        )}
                      </div>
                      <div className="form-group row text-center mt-5">
                        <div className="col-12">
                          <button
                            className="btn btn-block btn-custom waves-effect waves-light"
                            type="submit"
                          >
                            {isLoader && (
                              <div
                                class="spinner-border text-light spinner-border-sm mr-2"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            )}
                            SAVE AS INVENTORY ITEM
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default AddChemical;
