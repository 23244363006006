import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { appAxios } from "../../helpers/app-axios";
import Loader from "../loader";
const ChemicalsList = () => {
  const [chemicals, setChemicals] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    getChemicals();
  }, []);
  const getChemicals = async () => {
    setIsLoader(true);
    const { data } = await appAxios.get("/chemical");
    setIsLoader(false);
    if (data.isSuccess) {
      setChemicals(data?.data);
    }
  };
  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box p-5">
                  <div className="row">
                    <div className="col-xl-12">
                      <h4 className="header-title mb-3">
                        Registered chemicals list
                      </h4>
                      {isLoader ? (
                        <div className="text-center">
                          {" "}
                          <Loader type="text-dark" />
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-hover table-centered m-0">
                            <thead>
                              <tr>
                                <th>Chemical</th>
                                <th>Category</th>
                                <th>Supplier name</th>
                                <th>Active Ingredient</th>
                                <th>Batch Number</th>
                                <th>View detail</th>
                              </tr>
                            </thead>
                            <tbody>
                              {chemicals.length > 0 &&
                                chemicals.map((c, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>
                                        <h5 className="m-0 font-weight-normal">
                                          {c?.name}
                                        </h5>
                                      </td>
                                      <td>
                                        <h5 className="m-0 font-weight-normal">
                                          {c?.category}
                                        </h5>
                                      </td>
                                      <td>
                                        <h5 className="m-0 font-weight-normal">
                                          {c?.supplier}
                                        </h5>
                                      </td>
                                      <td>
                                        <h5 className="m-0 font-weight-normal">
                                          {c?.activeIngredient}
                                        </h5>
                                      </td>
                                      <td>
                                        <h5 className="m-0 font-weight-normal">
                                          {c?.batchNumber}
                                        </h5>
                                      </td>
                                      <td>
                                        <Link to={`/chemical-detail/${c?._id}`}>
                                          {" "}
                                          <i className="fa-regular fa-eye"></i>{" "}
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default ChemicalsList;
