import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { unsetUser } from "../../redux-store/user/user.slice";

const Topbar = () => {
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSearch = (e) => {
    e.preventDefault();
  };

  const handleLogout = () => {
    localStorage.removeItem("inventory_access_token");
    localStorage.removeItem("inventory_user");
    dispatch(unsetUser(null));
    navigate("/");
  };

  return (
    <div className="topbar">
      <nav className="navbar-custom">
        <ul className="list-unstyled topbar-right-menu float-right mb-0">
          <li className="hide-phone app-search">
            {/* <form onSubmit={handleSearch}>
              <input
                type="text"
                placeholder="Search..."
                className="form-control"
              />
              <button type="submit">
                <i className="fa fa-search"></i>
              </button>
            </form> */}
          </li>
          <li className="dropdown notification-list">
            <Link
              className="nav-link dropdown-toggle nav-user"
              data-toggle="dropdown"
              to="#"
              role="button"
              aria-haspopup="false"
              aria-expanded="false"
            >
              <img
                src="/assets/images/users/avatar-1.jpg"
                alt="user"
                className="rounded-circle"
              />{" "}
              <span className="ml-1">
                {user?.firstName} {(user?.lastName || "").slice(0, 1)}
                <i className="mdi mdi-chevron-down"></i>{" "}
              </span>
            </Link>
            <div className="dropdown-menu dropdown-menu-right dropdown-menu-animated profile-dropdown">
              {/* Item */}
              <div className="dropdown-item noti-title">
                <h6 className="text-overflow m-0">Welcome !</h6>
              </div>
              {/* Item */}
              <Link to="/myaccount">
              <span className="dropdown-item notify-item">
                <i className="fi-head"></i> <span>My Account</span>
              </span>
              </Link>
              {/* Item */}
              <button
                className="dropdown-item notify-item"
                onClick={handleLogout}
              >
                <i className="fi-power"></i> <span>Logout</span>
              </button>
            </div>
          </li>
        </ul>

        <ul className="list-inline menu-left mb-0">
          <li className="float-left">
            <button className="button-menu-mobile open-left disable-btn">
              <i className="dripicons-menu"></i>
            </button>
          </li>
          <li>
            <div className="page-title-box">
              <h4 className="page-title">Dashboard </h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                  Welcome to Smart admin panel !
                </li>
              </ol>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Topbar;
