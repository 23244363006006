import React, { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import { setUser } from "../../redux-store/user/user.slice";
import { useDispatch } from "react-redux";
import { appAxios } from "../../helpers/app-axios";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Email must be valid")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const Login = () => {
  const [apiError, setApiError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { handleChange, values, errors, handleSubmit, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema,
    async onSubmit(values) {
      console.log(values);
      setLoading(true);
      try {
        const { data } = await appAxios.post("/admin/login", values);
        localStorage.setItem(
          "inventory_access_token",
          data?.data?.access_token
        );
        localStorage.setItem(
          "inventory_user",
          JSON.stringify(data?.data?.user ?? {}) ?? null
        );
        dispatch(setUser(data?.data?.user ?? null));
        navigate("/", { replace: true });
      } catch (error) {
        setApiError(error?.response?.data?.message ?? "");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="wrapper-page account-page-full">
      <div className="card">
        <div className="card-block">
          <div className="account-box">
            <div className="card-box p-5">
              <h2 className="text-uppercase text-center pb-4">
                <div className="text-success">
                  <span>
                    <img src="assets/images/logo.png" alt="" height="40" />
                  </span>
                </div>
              </h2>
              <form className="" onSubmit={handleSubmit}>
                <div className="form-group m-b-20 row">
                  <div className="col-12">
                    <label>Email address</label>
                    <input
                      className="form-control"
                      placeholder="Enter your email"
                      name="email"
                      type="email"
                      onChange={handleChange}
                      value={values.email}
                    />
                    {touched.email && errors.email && (
                      <p className="mt-2 text-danger">{errors.email}</p>
                    )}
                  </div>
                </div>

                <div className="form-group row m-b-20">
                  <div className="col-12">
                    <label>Password</label>
                    <input
                      className="form-control"
                      placeholder="Enter your password"
                      name="password"
                      type="password"
                      onChange={handleChange}
                      value={values.password}
                    />
                    {touched.password && errors.password && (
                      <p className="mt-2 text-danger">{errors.password}</p>
                    )}
                  </div>
                </div>

                <div className="form-group row text-center mt-5">
                  <div className="col-12">
                    {apiError && (
                      <p className="mt-2 text-danger text-capitalize">
                        {apiError}
                      </p>
                    )}
                    <button
                      className="btn btn-block btn-custom waves-effect waves-light"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Sign in..." : "Sign in"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
