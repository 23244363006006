import React, { useEffect, useState } from 'react'

import Topbar from "../Topbar";
import Sidebar from "../Sidebar";
import { useParams } from 'react-router-dom';
import { appAxios } from '../../../helpers/app-axios';
import Loader from '../../loader';

const SingleUser = () => {
  const [user, setUser] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const {id} = useParams();
  useEffect(() => {
    getUserDetail()
  }, [])
  const getUserDetail = async () => {
    setIsLoader(true)
    const { data } = await appAxios.get(`/user/${id}`);
    setIsLoader(false)
    if (data.isSuccess) {
      setUser(data?.data);
    }
  };
  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
          {isLoader ? <div className="text-center">  <Loader type="text-dark"/></div> : <>
            <div className="user-profile-info">
              <h2 className="name">First name: <span>{user?.firstName}</span></h2>
              <h2 className="name">Last name: <span>{user?.lastName}</span></h2>
              <h2 className="name">Farm name: <span>{user?.farmName}</span></h2>
              <h2 className="name">Email: <span>{user?.email}</span></h2>
            </div>
            <div className="card">
              <div className="card-block">
                <div className="account-box p-5">
                  <div className="row">
                    <div className="col-xl-12">
                      <h4 className="header-title mb-3">
                      Inventories / chemicals
                      </h4>
                      <div className="table-responsive">
                        <table className="table table-hover table-centered m-0">
                          <thead>
                            <tr>
                              <th>Chemical</th>
                              <th>Category</th>
                              <th>Supplier name</th>
                              <th>Active Ingredient</th>
                              <th>Batch Number</th>
                              <th>Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            {user && Object.keys(user).length > 0 && user?.inventory && user?.inventory.length > 0 && user.inventory.map((i, index) => {
                              return <tr ky={index}>
                              <td>
                                <h5 className="m-0 font-weight-normal">
                                {i?.chemicalDetail?.name}
                                </h5>
                              </td>
                              <td>
                                <h5 className="m-0 font-weight-normal">
                                {i?.chemicalDetail?.category}
                                </h5>
                              </td>
                              <td>
                                <h5 className="m-0 font-weight-normal">
                                {i?.chemicalDetail?.supplier}
                                </h5>
                              </td>
                              <td>
                                <h5 className="m-0 font-weight-normal">
                                {i?.chemicalDetail?.activeIngredient}
                                </h5>
                              </td>
                              <td>
                                <h5 className="m-0 font-weight-normal">
                                {i?.chemicalDetail?.batchNumber}
                                </h5>
                              </td>
                              <td>
                              {i?.totalQuantity}
                              </td>
                            </tr>
                            })}
                          
                            
                            
                          
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </>
}
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  )
}

export default SingleUser