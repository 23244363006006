import React from "react";
import { useState } from "react";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";
import { Link } from "react-router-dom";

const EditChemical = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [quantity, setQuantity] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("chemical Name:", selectedOption);
  };
  const handleSelect = (e) => {
    const option = e.target.value;
    setSelectedOption(option);
  };
  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box">
                  <div className="card-box p-5">
                    <h2 className="text-uppercase text-center pb-4">
                      Add New Inventory Item
                    </h2>

                    <form className="" onSubmit={handleSubmit}>
                      <div className="form-group mb-4">
                        <label>Choose chemical</label>
                        <select
                          value={selectedOption}
                          onChange={handleSelect}
                          className="form-control"
                        >
                          <option value="">Select an option</option>
                          <option value="chemical1">Chemical 1</option>
                          <option value="chemical2">Chemical 2</option>
                          <option value="chemical3">Chemical 3</option>
                          <option value="chemical4">Chemical 4</option>
                        </select>
                      </div>

                      <div className="form-group  mb-4">
                        <label>Add Quantity</label>
                        <input
                          className="form-control"
                          type="text"
                          value={quantity}
                          onChange={(e) => setQuantity(e.target.value)}
                          placeholder="Add Quantity"
                        />
                      </div>

                      <div className="form-group row text-center mt-5">
                        <div className="col-12">
                          <Link to="/user-inventory/1">
                            <button
                              className="btn btn-block btn-custom waves-effect waves-light"
                              type="submit"
                            >
                              SAVE QUANTITY
                            </button>
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default EditChemical;
