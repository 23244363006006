import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AppToast({message, type}) {
  const toastId = React.useRef(null);

  const options = {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: true,
  };
  switch(type) {
    case "success":
      if(!toast.isActive("successId")) {
        toast.success(message, {...options, toastId: "successId",});;
      }
      
      break;
    case "warning":
      if(!toast.isActive("warningId")) {
        toast.warn(message, {...options, toastId: "warningId",});;
      }
      // toast.warn(message, options);
      break;
    case "error":
      if(toast.isActive("errorId")) {
        toast.error(message, {...options, toastId: "errorId",});;
      }
      // toast.error(message, options);
      break;
    case "info":
      if(!toast.isActive("infoId")) {
       toast.info(message, {...options, toastId: "infoId",});;
      }
      // toast.info(message, options);
      break;
  }
  return (
    <ToastContainer />
  )
}

// import React from "react";
// import "react-toastify/dist/ReactToastify.css";
// import { toast } from "react-toastify";

// export const successToast = () => {
//   return toast.success("Success", {
//     position: "top-right",
//     toastId: "successId",
//     style: {
//       backgroundColor: "#07bc0c",
//       color: "#fff",
//     },
//     // progressClassName: `${styles.successP}`,
//     bodyStyle: {
//       color: "white",
//     },
//   });
// };
// export function toastButton() {

//   const errorToast = () => {
//     toast.error('Error',
//       {
//         position: toast.POSITION.TOP_CENTER,
//         toastId: 'errorId',
//         style: {
//           backgroundColor: '#e74c3c',
//           color: '#fff'
//         },
//         // progressClassName: `${styles.errorP}`
//       });
//   };
//   const infoToast = () => {
//     toast.info('Info',
//       {
//         position: toast.POSITION.TOP_CENTER,
//         toastId: 'infoId',
//         style: {
//           backgroundColor: '#f1c40f',
//           color: '#fff'
//         },
//         // progressClassName: `${styles.infoP}`
//       });
//   };
// }
