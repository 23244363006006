import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { useParams } from "react-router-dom";
import { appAxios } from "../../helpers/app-axios";
import moment from "moment";
import Loader from "../loader";

const ChemicalDetail = () => {
  const [chemical, setChemical] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const {id} = useParams();
  useEffect(() => {
    getChemical();
  }, []);
  const getChemical = async () => {
    setIsLoader(true)
    const { data } = await appAxios.get(`/chemical/${id}`);
    setIsLoader(false)

    if (data.isSuccess) {
      setChemical(data?.data);
    }
  };
  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box p-5">
                  <div className="row">
                    <div className="col-12">
                      {isLoader ? <div className="text-center"><Loader  type="text-dark"/></div> : chemical && Object.keys(chemical).length > 0 && (
<div className="chemical-wrapper">
                        <h2 className="name">{chemical?.name}</h2>
                        <h3 className="subtitle">
                          Categor: <span>{chemical?.category}</span>
                        </h3>
                        <h3 className="subtitle">
                          Supplier: <span>{chemical?.supplier}</span>
                        </h3>
                        <h3 className="subtitle">
                          Active ingredient: <span>{chemical?.activeIngredient}</span>
                        </h3>
                        <h3 className="subtitle">
                          Date of Manufacture: <span>{moment(chemical?.createdDate).format("LL")}</span>
                        </h3>
                        <h3 className="subtitle">
                          Batch Numner: <span>{chemical?.batchNumber}</span>
                        </h3>
                        <div className="mt-4">
                          <a href={`${process.env.REACT_APP_DOWNLOAD_URL}${chemical?.file}`} target="_blank" className="btn  btn-custom waves-effect waves-light">
                            DOWNLAOD PDF
                          </a>
                        </div>
                      </div>
                      )}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default ChemicalDetail;
