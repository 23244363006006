import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Sidebar = () => {
  const user = useSelector((state) => state.user.user);

  return (
    <div className="left side-menu">
      <div className="slimscroll-menu" id="remove-scroll">
        {/* LOGO */}
        <div className="topbar-left">
          <Link to="/">
            <div className="logo">
              <span>
                <img src="/assets/images/logo.png" alt="" />
              </span>
            </div>
          </Link>
        </div>
        {/* User box */}
        <div className="user-box">
          <div className="user-img">
            <img
              src="/assets/images/users/avatar-1.jpg"
              alt="user-img"
              title="Mat Helme"
              className="rounded-circle img-fluid"
            />
          </div>
          <h5>
            <span>
              {user?.firstName} {user?.lastName}
            </span>{" "}
          </h5>
          {/* <p className="text-muted">Admin Head</p> */}
        </div>
        {/* Sidemenu */}
        <div id="sidebar-menu">
          <ul className="metismenu" id="side-menu">
            <li>
              <Link to="/">
                <i className="fa-solid fa-gauge"></i> <span> Dashboard </span>
              </Link>
            </li>

            <li>
              <Link to="/myaccount">
                <i className="fa-regular fa-user"></i> <span> My Account </span>
              </Link>
            </li>
            <li>
              <Link to="/users">
                <i className="fa-solid fa-users"></i>{" "}
                <span> Users Inventory </span>
              </Link>
            </li>
            <li>
              <Link to="/chemical-list">
                <i className="fa-solid fa-flask"></i>{" "}
                <span>Registered Chemicals </span>
              </Link>
            </li>
            <li>
              <Link to="/inquiries">
                <i className="fa-regular fa-address-card"></i>{" "}
                <span>Customer Inquiries </span>
              </Link>
            </li>
          </ul>
        </div>
        {/* Sidebar  */}
        <div className="clearfix"></div>
      </div>
      {/* Sidebar -left  */}
    </div>
  );
};

export default Sidebar;
