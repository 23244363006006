import React, { useEffect, useState } from "react";
import Topbar from "../Topbar";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import { appAxios } from "../../../helpers/app-axios";
import Loader from "../../loader";

const Users = () => {
  const [customer, setCustomer] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  useEffect(() => {
    getCustomers();
  }, []);
  const getCustomers = async () => {
    setIsLoader(true)
    const { data } = await appAxios.get("/user?type=customer");
    setIsLoader(false)
    if (data.isSuccess) {
      setCustomer(data?.data);
    }
  };
  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box">
                  <div className="card-box">
                    <div className="row">
                      <div className="col-xl-12">
                        <h4 className="header-title mb-3">User list</h4>
                        <div className="table-responsive">
                          {isLoader ? <div className="text-center">  <Loader type="text-dark"/></div> : 
                          <table className="table table-hover table-centered m-0">
                            <thead>
                              <tr>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Chemicals(Quantity)</th>
                                <th>View</th>
                              </tr>
                            </thead>
                            <tbody>
                              {customer.length > 0 &&
                                customer.map((c, index) => {
                                  return (
                                  <tr key={index}>
                                     
                                      <td>
                                        <h5 className="m-0 font-weight-normal">
                                          {c?.firstName + " " + c?.lastName}
                                        </h5>
                                        {/* <p className="mb-0 text-muted">
                                    <small>Member Since 2017</small>
                                  </p> */}
                                      </td>

                                      <td>{c?.email}</td>

                                      <td>
                                        <h5 className="m-0 font-weight-normal">
                                          {c?.inventory}
                                        </h5>
                                      </td>
                                   <td>
                                   <Link to={`/user/${c?._id}`}>   <i className="fa-regular fa-eye">

                                        </i>
                                        </Link> 
                                      </td>
                                     
                                    </tr>
                                    
                                  );
                                })}
                            </tbody>
                          </table>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default Users;
