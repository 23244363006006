import axios from "axios";
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
export const appAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// appAxios.interceptors.response.use(undefined, (error) => {
//   console.log(error?.response?.data);
//   return error?.response?.data ?? {};
// });

appAxios.interceptors.request.use(
  function (config) {
    config.headers.Authorization = localStorage.getItem(
      "inventory_access_token"
    );
    return config;
  },
  null,
  { synchronous: true }
);
