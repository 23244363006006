import React from "react";
 const Loader = ({type}) => {
    return (<div
        class={`spinner-border ${type}  mr-2`}
        role="status"
      >
        <span class="sr-only">
          Loading...
        </span>
      </div>)
}
export default Loader