import React, { useEffect, useState } from "react";
import Topbar from "../Topbar";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";
import { appAxios } from "../../../helpers/app-axios";
import Loader from "../../loader";

const Home = () => {
  const [stats, setStats] = useState(null)
  const [isLoader, setIsLoader] = useState(false)
  useEffect(() => {
    getStats();
  }, [])
  const getStats = async () => {
    setIsLoader(true)
    const { data } = await appAxios.get("/stats");
    setIsLoader(false)

    if(data.isSuccess){
      setStats(data?.data)
    }

  }
  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box p-5">
                  <div className="row mb-5">
                    <div className="col-xl-6">
                      <Link to="/users">
                        <div className="card-box-shadow">
                          <div className="media my-media">
                            <div className="anlytic-img">
                              <i className="fa-solid fa-flask"></i>
                            </div>
                            <div className="media-body my-media-body">
                              <h6 className="mt-0">
                                Total registered customers
                              </h6>
                              <h3>
                                {isLoader ? <Loader type="text-dark"/> : 
                                stats?.totalCustomer
                                }
                                </h3>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-xl-6">
                      <Link to="/chemical-list">
                        <div className="card-box-shadow">
                          <div className="media my-media">
                            <div className="anlytic-img">
                              <i className="fa-solid fa-users"></i>
                            </div>
                            <div className="media-body my-media-body">
                              <h6 className="mt-0">
                                Total chemicals available
                              </h6>
                              <h3>{isLoader ? <Loader type="text-dark"/> : 
                                stats?.totalChemical
                                }</h3>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end">
                    <Link to="/add-chemical">
                      <button
                        className="btn btn-custom btn-custom-large waves-effect waves-light"
                        type="submit"
                      >
                        ADD NEW INVENTORY ITEM
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default Home;
