import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import Topbar from "../Topbar";

const UserInventory = () => {
  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box p-5">
                  <div className="row">
                    <div className="col-12 col-lg-6">
                      <div className="chemical-wrapper">
                        <h2 className="name">Chamical Name</h2>
                        <h3 className="subtitle">
                          Categor: <span>Test category</span>
                        </h3>
                        <h3 className="subtitle">
                          Active ingredient: <span>Test ingredient</span>
                        </h3>
                        <h3 className="subtitle">
                          Quantity: <span>5</span>
                        </h3>
                        <div className="mt-3">
                          <Link to="/single-chemical/1">
                            <button className="btn  btn-custom waves-effect waves-light">
                              View detail
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="chemical-wrapper">
                        <h2 className="name">Chamical Name</h2>
                        <h3 className="subtitle">
                          Categor: <span>Test category</span>
                        </h3>
                        <h3 className="subtitle">
                          Active ingredient: <span>Test ingredient</span>
                        </h3>
                        <h3 className="subtitle">
                          Quantity: <span>5</span>
                        </h3>
                        <div className="mt-3">
                          <Link to="/single-chemical/1">
                            <button className="btn  btn-custom waves-effect waves-light">
                              View detail
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="chemical-wrapper">
                        <h2 className="name">Chamical Name</h2>
                        <h3 className="subtitle">
                          Categor: <span>Test category</span>
                        </h3>
                        <h3 className="subtitle">
                          Active ingredient: <span>Test ingredient</span>
                        </h3>
                        <h3 className="subtitle">
                          Quantity: <span>5</span>
                        </h3>
                        <div className="mt-3">
                          <Link to="/single-chemical/1">
                            <button className="btn  btn-custom waves-effect waves-light">
                              View detail
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6">
                      <div className="chemical-wrapper">
                        <h2 className="name">Chamical Name</h2>
                        <h3 className="subtitle">
                          Categor: <span>Test category</span>
                        </h3>
                        <h3 className="subtitle">
                          Active ingredient: <span>Test ingredient</span>
                        </h3>
                        <h3 className="subtitle">
                          Quantity: <span>5</span>
                        </h3>
                        <div className="mt-3">
                          <Link to="/single-chemical/1">
                            <button className="btn  btn-custom waves-effect waves-light">
                              View detail
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default UserInventory;
