import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route } from "react-router-dom";
import Home from "./components/admin/home/Home";
import Login from "./components/login/Login";
import AddChemical from "./components/admin/AddChemical";
import ChemicalsList from "./components/admin/ChemicalsList";
import ChemicalDetail from "./components/admin/ChemicalDetail";
import MyAccount from "./components/admin/MyAccount";
import Users from "./components/admin/user-inventory/Users";
import UserInventory from "./components/admin/user-inventory/UserInventory";
import SingleChemicalDetail from "./components/admin/user-inventory/SingleChemicalDetail";
import EditChemical from "./components/admin/user-inventory/EditChemical";
import SingleUser from "./components/admin/user-inventory/SingleUser";
import Inquiries from "./components/admin/Inquiries";
import { useEffect } from "react";
import { setUserSlice } from "./redux-store/user/user.slice";

function App() {
  const { user, initializing } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    let user = localStorage.getItem("inventory_user") ?? null;
    if (user) user = JSON.parse(user);
    dispatch(
      setUserSlice({
        user,
        initializing: false,
      })
    );
  }, [dispatch]);

  if (initializing) return null;

  let routes = <></>;

  if (user) {
    routes = (
      <>
        <Route path="/" exact element={<Home />} />

        <Route path="/add-chemical" exact element={<AddChemical />} />
        <Route path="/chemical-list" exact element={<ChemicalsList />} />
        <Route path="/chemical-detail/:id" exact element={<ChemicalDetail />} />
        <Route path="/myaccount" exact element={<MyAccount />} />
        <Route path="/users" exact element={<Users />} />
        <Route path="/user-inventory/:id" exact element={<UserInventory />} />
        <Route
          path="/single-chemical/:id"
          exact
          element={<SingleChemicalDetail />}
        />
        <Route path="/edit-chemical" exact element={<EditChemical />} />
        <Route path="/inquiries" exact element={<Inquiries />} />
        <Route path="/user/:id" exact element={<SingleUser />} />
      </>
    );
  }

  if (!user) {
    routes = (
      <>
        <Route path="/" exact element={<Login />} />
      </>
    );
  }

  return <Routes>{routes}</Routes>;
}

export default App;
