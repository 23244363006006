import React from "react";
import Topbar from "../Topbar";
import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";

const SingleChemicalDetail = () => {
  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box p-5">
                  <div className="row">
                    <div className="col-12">
                      <div className="chemical-wrapper">
                        <h2 className="name">Chamical Name</h2>
                        <h3 className="subtitle">
                          Categor: <span>Test category</span>
                        </h3>
                        <h3 className="subtitle">
                          Supplier: <span>Test supplier</span>
                        </h3>
                        <h3 className="subtitle">
                          Active ingredient: <span>Test ingredient</span>
                        </h3>
                        <h3 className="subtitle">
                          Date of Manufacture: <span>10/03/2023</span>
                        </h3>
                        <h3 className="subtitle">
                          Batch Numner: <span>KNS455</span>
                        </h3>
                        <div className="mt-4">
                          <button className="btn  btn-custom waves-effect waves-light">
                            DOWNLAOD PDF
                          </button>
                          <Link to="/edit-chemical">
                            <button className="btn  btn-custom waves-effect waves-light ml-4">
                              EDIT QUANTITY ON HAND
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default SingleChemicalDetail;
