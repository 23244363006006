import React from "react";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { appAxios } from "../../helpers/app-axios";
import { setUser } from "../../redux-store/user/user.slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../loader";

const validationSchema = Yup.object({
  firstName: Yup.string().trim().required("First name is required"),
  lastName: Yup.string().trim().required("Last name is required"),
  farmName: Yup.string().trim().required("Farm name is required"),
});

const MyAccount = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const { handleChange, values, setValues, errors, handleSubmit, touched } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        farmName: "",
      },
      validationSchema,
      async onSubmit(values) {
        setLoading(true);
        try {
          const { data } = await appAxios.post("/user/profile", values);
          if (data?.data) dispatch(setUser(data.data));
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      },
    });

  const [showEdit, setShowEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    setValues({
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      farmName: user?.farmName ?? "",
    });
  }, [user, setValues]);

  return (
    <div id="wrapper">
      <Sidebar />
      <div className="content-page">
        {/* Top bar start */}
        <Topbar />
        {/* Top bar end */}
        {/* Start page content */}
        <div className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-block">
                <div className="account-box">
                  <div className="card-box p-5">
                    <h2 className="text-uppercase text-center pb-4">
                      My Account
                    </h2>
                    <div className="account-info text-center">
                      <h2 className="name">
                        {user?.firstName} {user?.lastName}
                      </h2>
                      <h2 className="name">{user?.farmName}</h2>
                      <button
                        className="btn  btn-custom waves-effect waves-light mt-3"
                        onClick={() => setShowEdit(true)}
                      >
                        Edit Account
                      </button>
                    </div>

                    {showEdit && (
                      <div className="edi-account mt-5">
                        <form className="" onSubmit={handleSubmit}>
                          <div className="form-group mb-4">
                            <label>First name</label>
                            <input
                              className="form-control"
                              placeholder="First name"
                              name="firstName"
                              type="text"
                              onChange={handleChange}
                              value={values.firstName}
                            />

                            {touched.firstName && errors.firstName && (
                              <p className="mt-2 text-danger">
                                {errors.firstName}
                              </p>
                            )}
                          </div>

                          <div className="form-group  mb-4">
                            <label>Last name</label>
                            <input
                              className="form-control"
                              placeholder="Last Name"
                              name="lastName"
                              type="text"
                              onChange={handleChange}
                              value={values.lastName}
                            />

                            {touched.lastName && errors.lastName && (
                              <p className="mt-2 text-danger">
                                {errors.lastName}
                              </p>
                            )}
                          </div>
                          <div className="form-group  mb-4">
                            <label>Farm name</label>
                            <input
                              className="form-control"
                              placeholder="Farm Name"
                              name="farmName"
                              type="text"
                              onChange={handleChange}
                              value={values.farmName}
                            />
                            {touched.farmName && errors.farmName && (
                              <p className="mt-2 text-danger">
                                {errors.farmName}
                              </p>
                            )}
                          </div>
                          <div className="form-group row text-center mt-5">
                            <div className="col-12">
                              <button
                                className="btn btn-custom waves-effect waves-light"
                                type="submit"
                                disabled={loading}
                              >
                                {loading && (
                                  <div
                                    class="spinner-border text-light spinner-border-sm mr-2"
                                    role="status"
                                  >
                                    <span class="sr-only">Loading...</span>
                                  </div>
                                )}
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="footer">2023 © Smart.</footer>
      </div>
    </div>
  );
};

export default MyAccount;
